import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import SEO from '../../../components/SEO'
import Accordion from '../../../components/Accordion'
import {
  ProductSpotlight,
  ProductFeaturesCard,
} from '../../../components/Product'
import StepsCards from '../../../components/Cards/StepsCards'
import {
  ContentfulList,
  ContentfulProduct,
  ContentfulText,
  JitterbugFlipUpgradeQuery,
} from '../../../../types/graphql-types'
import {
  AccordionStyled,
  AccordionTitle,
  AccordionCarrot,
  CollapseBody,
} from '../../../styles/support-styles'
import LayoutMinimal from '../../../components/Layout/Minimal'

interface Props {
  data: JitterbugFlipUpgradeQuery
}

const HeroBackground = styled.section`
  ${tw`max-w-1920 mx-auto md:h-64`}
`
const HeroTextBox = styled.div`
  ${tw`flex content-center flex-wrap h-full`}
  div {
    ${tw`container py-12 text-left text-white`}
    h1 {
      ${tw`leading-none mb-4`}
    }
    h2 {
      ${tw`font-normal`}
    }
  }
`
const LongSimpleStyled = styled.div`
  h2 {
    ${tw`mb-4`}
  }
`

export const query = graphql`
  query JitterbugFlipUpgrade {
    carousel: allContentfulList(filter: { externalName: { eq: "Gallery" } }) {
      nodes {
        name
        items {
          ... on ContentfulMedia {
            id
            name
            media {
              file {
                url
              }
            }
          }
        }
      }
    }
    contentfulPage(externalName: { eq: "CDMA Flip" }) {
      title
      externalName
      slug
      seoTitle
      seoDescription
      noindex
      type
      gtmPageType
      references {
        ... on ContentfulHero {
          id
          name
          externalName
          references {
            ... on ContentfulText {
              id
              name
              externalName
              longSimpleText {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        ... on ContentfulFeatureContent {
          id
          name
          externalName
          references {
            ... on ContentfulText {
              id
              name
              externalName
              longSimpleText {
                id
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        ... on ContentfulProduct {
          id
          deviceName
          deviceShortName
          deviceTypeId
          deviceCategory
          buySlug
          productPurchaseInfo {
            id
            colors {
              color
            }
          }
          slogans {
            name
            externalName
            shortSimpleText
          }
          productSpecs {
            ... on ContentfulList {
              id
              name
            }
          }
          carousel: gallery {
            name
            externalName
            items {
              ... on ContentfulMedia {
                id
                name
                media {
                  file {
                    url
                  }
                }
              }
            }
          }
          references {
            ... on ContentfulCards {
              id
              title
              externalName
              cards {
                ... on ContentfulCard {
                  id
                  title
                  headline
                  slug
                  featuredImage {
                    gatsbyImageData(layout: FULL_WIDTH)
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
          faQs {
            ... on ContentfulList {
              id
              openFaq
              externalName
              items {
                ... on ContentfulText {
                  name
                  id
                  externalName
                  longSimpleText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
          }
          features {
            id
            name
            externalName
            items {
              ... on ContentfulText {
                id
                name
                shortSimpleText
              }
              ... on ContentfulLink {
                id
                name
                externalName
                type
                ctaLink
              }
            }
          }
          media {
            name
            altText
            media {
              file {
                url
              }
            }
          }
        }
        ... on ContentfulText {
          id
          name
          shortSimpleText
          longSimpleText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulCards {
          id
          title
          externalName
          cards {
            ... on ContentfulCard {
              id
              title
              headline
              featuredImage {
                id
                title
                stepCardsCDN: gatsbyImageData(layout: FULL_WIDTH)
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
const JitterbugFlipUpgradePage: React.FC<Props> = (props: Props) => {
  const data = props.data
  const allContent = data.contentfulPage

  // Get product
  const getProduct = (allContent?.references as ContentfulProduct[]).find(
    product => product.deviceName === 'Jitterbug Flip'
  )

  // Get FAQ list
  const getFaqList = (getProduct?.faQs as ContentfulList[]).find(
    faqs => faqs.externalName === 'FAQs'
  )

  // Get Open FAQ radio button
  const openFaq = getFaqList?.openFaq

  // Get first FAQ
  const getFaq = (getFaqList?.items as ContentfulText[]).find(
    faq => faq.externalName === 'Faq'
  )

  // Get first FAQ ID
  const indexFaq = getFaq?.id

  {
    /* If Open FAQ is set to true then indexFaq will be 
    first FAQ Id otherwise it will be set to empty string.*/
  }
  const [activeEventKey, setActiveEventKey] = useState(
    openFaq === true ? `${indexFaq}` : ''
  )

  const [isFeaturesHidden, setIsFeaturesHidden] = useState(true)
  const showMoreFeatures = () => {
    setIsFeaturesHidden(false)
  }

  let hero: any = []
  let pageIntro: any = []
  let features: any = []
  let stepsHeader: any = []
  let stepCards: any = []
  let faqs: any = []

  allContent?.references?.map((content: any, index) => {
    if (content?.name === 'CDMA Flip Hero') {
      hero = (
        <HeroBackground className="gradient-5-lively">
          <HeroTextBox>
            <div>
              {content?.references?.map(
                (heroText: {
                  name: string
                  id: React.Key | null | undefined
                  longSimpleText: { childMarkdownRemark: { html: any } }
                }) => {
                  if (heroText?.name === 'CDMA Hero Text')
                    return (
                      <div
                        key={heroText?.id}
                        dangerouslySetInnerHTML={{
                          __html:
                            heroText?.longSimpleText?.childMarkdownRemark?.html,
                        }}
                      ></div>
                    )
                }
              )}
            </div>
          </HeroTextBox>
        </HeroBackground>
      )
    }

    if (content?.name === 'CDMA Flip Intro') {
      pageIntro = (
        <section className="content-section" key={content.id}>
          <div className="px-0 md:px-8 lg:px-0">
            {content?.references?.map(
              (introContent: {
                longSimpleText: { childMarkdownRemark: { html: any } }
              }) => {
                return (
                  <LongSimpleStyled
                    key={index}
                    className="mb-16 text-lg md:text-center lg:mb-32 xl:px-48"
                    dangerouslySetInnerHTML={{
                      __html:
                        introContent.longSimpleText?.childMarkdownRemark?.html,
                    }}
                  ></LongSimpleStyled>
                )
              }
            )}
          </div>
        </section>
      )
    }

    if (content?.deviceName === 'Jitterbug Flip') {
      features = content?.references.map(phoneContent => {
        if (phoneContent?.externalName === 'Feature Cards') {
          return (
            <ProductFeaturesCard
              key={index}
              phoneContent={phoneContent}
              isFeaturesHidden={isFeaturesHidden}
              showMoreFeatures={showMoreFeatures}
            />
          )
        }
      })
      faqs = (
        <section className="content-section">
          <h2 className="mb-8">Frequently Asked Questions</h2>
          <Accordion
            activeEventKey={activeEventKey}
            onToggle={setActiveEventKey}
            key={content?.id}
          >
            {content?.faQs?.map(list => {
              if (list.externalName === 'FAQs') {
                return (
                  <React.Fragment key={list.id}>
                    {list.items?.map((faq, index) => {
                      return (
                        <AccordionStyled key={faq?.id} {...props}>
                          <Accordion.Toggle
                            data-analytics-faq={faq?.name}
                            eventKey={faq?.id}
                            aria-controls={`${faq?.id}-controls`}
                            aria-expanded={
                              activeEventKey === faq?.id ? true : false
                            }
                            id={faq?.id}
                            className="flex w-full items-center justify-between p-4"
                          >
                            <AccordionTitle>
                              <div>
                                <p>{faq?.name}</p>
                              </div>
                            </AccordionTitle>
                            <AccordionCarrot
                              className={`icon-angle-down`}
                            ></AccordionCarrot>
                          </Accordion.Toggle>
                          <Accordion.Collapse
                            eventKey={faq?.id}
                            role="region"
                            aria-labelledby={faq?.id}
                            id={`${faq?.id}-controls`}
                          >
                            <CollapseBody
                              className="px-4"
                              dangerouslySetInnerHTML={{
                                __html:
                                  faq?.longSimpleText?.childMarkdownRemark
                                    ?.html,
                              }}
                            ></CollapseBody>
                          </Accordion.Collapse>
                        </AccordionStyled>
                      )
                    })}
                  </React.Fragment>
                )
              }
            })}
          </Accordion>
        </section>
      )
    }

    if (content?.name === 'Steps Header') {
      stepsHeader = (
        <LongSimpleStyled
          className="px-4 pt-8 text-lg md:text-center xl:px-48"
          dangerouslySetInnerHTML={{
            __html: content?.longSimpleText?.childMarkdownRemark?.html,
          }}
        ></LongSimpleStyled>
      )
    }

    if (content?.externalName === 'Steps Cards') {
      stepCards = (
        <section className="content-section pb-0 lg:pt-4" key={index}>
          <ul key={content.id} className="grid-cols-3 gap-7 md:grid">
            {content?.cards.map(
              (card: {
                id: React.Key | null | undefined
                title: string
                category: string
                headline: string | number | null | undefined
                featuredImage: {
                  stepCards: IGatsbyImageData
                }
              }) => {
                return (
                  <li key={card.id}>
                    <StepsCards
                      title={card?.title}
                      category={card?.category}
                      headline={card?.headline}
                      featuredImage={card?.featuredImage.stepCardsCDN}
                    />
                  </li>
                )
              }
            )}
          </ul>
        </section>
      )
    }
  })

  return (
    <LayoutMinimal>
      <SEO
        title={allContent?.seoTitle as string}
        description={allContent?.seoDescription as string}
        noIndex={allContent?.noindex as boolean}
      />
      {hero}
      {pageIntro}
      <ProductSpotlight data={data} deviceDetails="Jitterbug Flip" />
      {features}
      {stepsHeader}
      {stepCards}
      {faqs}
    </LayoutMinimal>
  )
}

export default JitterbugFlipUpgradePage
