import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import ContentfulRichText from '../../ContentfulRichText'
import { ContentfulTextRichText, Maybe } from '../../../../types/graphql-types'

interface Props {
  title: string
  category?: string
  numberColor?: string
  headline?: string | number | null | undefined
  headlineLong?: string | number | null | undefined
  headlineRichText?: Maybe<ContentfulTextRichText> | undefined
  featuredImage: IGatsbyImageData
  altText: string
}

const StepCard = styled.div`
  ${tw`relative h-full w-full mb-8 md:mb-0`}
`

const StepCopyLongText = styled.div`
  ${tw`text-left`}
`
const StepCopy = styled.p`
  ${tw`text-left`}
`

const StepNumber = styled.span`
  ${tw`h-12 w-12 text-3xl md:(h-8 w-8 text-xl) lg:(h-12 lg:w-12 lg:text-3xl) inline-flex items-center font-bold justify-center rounded-full text-white leading-none absolute top-2 right-2 z-40`}
  span {
    ${tw`absolute top-1.5 md:-top-0 lg:top-1`}
  }
`

const StepsCards: React.FC<Props> = ({
  title,
  category,
  numberColor,
  headline,
  headlineLong,
  headlineRichText,
  featuredImage,
  altText,
}) => (
  <StepCard>
    <div className="relative mb-3">
      <StepNumber className={numberColor ? numberColor : 'bg-bby-blue-700'}>
        <span className="sr-only">Step</span>
        <span>{title}</span>
      </StepNumber>
      <GatsbyImage image={featuredImage} alt={altText} />
    </div>
    {category ? <h3 className="mb-2 text-2xl">{category}</h3> : ''}
    {headlineLong && (
      <StepCopyLongText
        dangerouslySetInnerHTML={{
          __html: headlineLong as string,
        }}
      />
    )}
    {headlineRichText && <ContentfulRichText document={headlineRichText} />}
    {headline && <StepCopy>{headline}</StepCopy>}
  </StepCard>
)

export default StepsCards
